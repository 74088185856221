import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";

import { useDocumentLanguage } from "hooks";
import CookieConsentConfig from "types/state/CookieConsentConfig";

const CookieConsent = ({ id }: CookieConsentConfig): ReactElement => {
    const language = useDocumentLanguage();

    const onConsent = () => {
        const marketingConsent = window.Cookiebot.consent.marketing;
        document.cookie = `cookiesconsented=${
            window.Cookiebot.consent.marketing ? "1" : "0"
        }`;

        // Notify Microsoft Clarity about consent or revoke it
        if (window.clarity) {
            if (marketingConsent) {
                window.clarity("consent");
            } else {
                window.clarity("consent", false); // Erase Clarity cookies
            }
        }

        // CookiebotOnAccept fires pretty much always, so make sure something _really_ has changed
        if (window.Cookiebot.changed) {
            setTimeout(() => {
                window.location.href = window.location.href?.replace(
                    window.location.hash,
                    "",
                );
            }, 1000);
        }
    };

    React.useEffect(() => {
        window.addEventListener("CookiebotOnAccept", onConsent);

        // cleanup listeners
        return () => {
            window.removeEventListener("CookiebotOnAccept", onConsent);
        };
    });

    return (
        <Helmet>
            <script
                async
                id="Cookiebot"
                src="https://consent.cookiebot.com/uc.js"
                data-cbid={id}
                data-culture={language}
                data-blockingmode="auto"
                data-domain
                type="text/javascript"
            ></script>
        </Helmet>
    );
};

export default React.memo(CookieConsent);
