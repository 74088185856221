import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FormMortgagePageProps from "./FormMortgagePageProps";

const FormMortgagePage = loadable(
    (): Promise<DefaultComponent<FormMortgagePageProps>> =>
        import(/* webpackChunkName: "FormMortgagePage" */ "./FormMortgagePage"),
);
const FormMortgagePageLoader = (props: FormMortgagePageProps): ReactElement => (
    <FormMortgagePage {...props} />
);
export default FormMortgagePageLoader;
