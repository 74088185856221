import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import CalculatedLoanAmountProps from "./CalculatedLoanAmountProps";

const CalculatedLoanAmount = loadable(
    (): Promise<DefaultComponent<CalculatedLoanAmountProps>> =>
        import(
            /* webpackChunkName: "CalculatedLoanAmount" */ "./CalculatedLoanAmount"
        ),
);
const CalculatedLoanAmountLoader = (
    props: CalculatedLoanAmountProps,
): ReactElement => <CalculatedLoanAmount {...props} />;

export default CalculatedLoanAmountLoader;
